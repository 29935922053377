import axios from 'axios';

import { setToken } from './token';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        "Content-Type": "application/json"
    }/*,
    withCredentials: true // Permite o envio de cookies/sessões*/
})

api.interceptors.request.use(
    request => {
        const token = sessionStorage.getItem('token');

        if (token) request.headers.authorization = `Bearer ${token}`;

        return request;
    },
    error => {
        return Promise.reject(error); // Lida com erros no request
    }
)

//saveAs(response.data, 'teste.csv');

api.interceptors.response.use(
    response => response,
    error => {
        const status = error?.response?.status || 0;

        if (status === 401) {
            setToken('');
            window.location.replace('/login');
            return Promise.reject();
        } else if (status === 403) {
            window.location.replace('/');
            return Promise.reject();
        }

        return Promise.reject(error);
    });

export default api;