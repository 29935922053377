import { Col, FlexboxGrid, Stack, Tooltip, Whisper } from "rsuite";

import './style.css';

const DadosField = ({ label, value, size, style, styleContainer, helpText }) => {
    return (
        <FlexboxGrid.Item
            as={Col}
            lg={(size && size.lg) || 24}
            md={(size && size.md) || 24}
            sm={(size && size.sm) || 24}
            style={styleContainer || null}
        >
            <div className="field-container">
                <Stack spacing={10}>
                    <label>{label}</label>
                    {helpText &&
                        <Whisper placement="top" speaker={<Tooltip>{helpText}</Tooltip>}>
                            <div className="header-help">?</div>
                        </Whisper>
                    }
                </Stack>
                <div className="field-value" style={style || null}>
                    {value}
                </div>
            </div>
        </FlexboxGrid.Item>
    )
}

export default DadosField