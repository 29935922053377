import api from '../services/api.js';

const getRegiaoIntermediaria = async ({ start, pageNumber, itemsPerPage, searchText, uf, arrayCodigos }) => {
    const response = await api.get(`/regiaointermediaria`, { params: { start, pageNumber, itemsPerPage, searchText, uf, arrayCodigos } });
    return response.data;
}

const getRegiaoImediata = async ({ start, pageNumber, itemsPerPage, searchText, uf, arrayCodigos }) => {
    const response = await api.get(`/regiaoimediata`, { params: { start, pageNumber, itemsPerPage, searchText, uf, arrayCodigos } });
    return response.data;
}

const getMunicipios = async ({ start, pageNumber, itemsPerPage, searchText, uf, arrayCodigos }) => {
    const response = await api.get(`/municipios`, { params: { start, pageNumber, itemsPerPage, searchText, uf, arrayCodigos } });
    return response.data;
}

const getCnaes = async ({ start, pageNumber, itemsPerPage, searchText, arrayCodigos }) => {
    const response = await api.get('/cnaes', { params: { start, pageNumber, itemsPerPage, searchText, arrayCodigos } });
    return response.data;
}

const getQualiicacao = async ({ start, pageNumber, itemsPerPage, searchText, arrayCodigos }) => {
    const response = await api.get('/qualificacao', { params: { start, pageNumber, itemsPerPage, searchText, arrayCodigos } });
    return response.data;
}

const getUFs = async ({ start, pageNumber, itemsPerPage, searchText, arrayCodigos }) => {
    const response = await api.get('/ufs', { params: { start, pageNumber, itemsPerPage, searchText, arrayCodigos } });
    return response.data;
}

const getFormaTributacao = async () => {
    const response = await api.get('/formatributacao');
    return response.data;
}

export const useApiRFBTabelas = () => ({
    getRegiaoIntermediaria,
    getRegiaoImediata,
    getMunicipios,
    getCnaes,
    getUFs,
    getQualiicacao,
    getFormaTributacao
})