import api from '../services/api.js';

const resource = '/download'

const getDownloads = async () => {
    const response = await api.get(`${resource}`);
    return response.data;
}

const checkNewFilesRFB = () => api.get(`${resource}/checknewfiles`)
const updateDataRFB = () => api.get(`${resource}/update`)

export const useApiDownload = () => ({
    getDownloads,
    checkNewFilesRFB,
    updateDataRFB
})