import { useEffect, useRef, useState } from "react";
import { Button, FlexboxGrid, InputPicker, Loader, MaskedInput, Modal, Panel, Stack, Tabs, useToaster } from "rsuite";

import { showMessage } from "../../components/ShowMessage";

import { useApiCnpjConsulta } from "../../hooks/useApiCnpjConsulta";

import { dateFromString, formataCEP, formataCNPJ, formataTelefone, mask } from "../../services/utils";

import './style.css';

import Loading from "../../components/Loading";
import DadosCadastro from './DadosCadastro';
import DadosCNAE from './DadosCnae';
import DadosEmpresaMesmoSocio from './DadosEmpresaMesmoSocio';
import DadosEmpresasSocias from './DadosEmpresasSocias';
import DadosField from "./DadosField";
import DadosSocios from './DadosSocios';

const CnpjConsultar = ({ cnpj = '' }) => {
    const apiCnpjConsulta = useApiCnpjConsulta();

    console.log(cnpj)

    const [cnpjValue, setCnpjValue] = useState(cnpj)
    const [mesmoGrupo, setMesmoGrupo] = useState()
    const [dados, setDados] = useState({})

    const [socioOutraEmpresa, setSocioOutraEmpresa] = useState()
    const [outrasEmpresas, setOutrasEmpresas] = useState()
    const [empresaSocia, setEmpresaSocia] = useState()

    const [waiting, setWaiting] = useState(false)
    const [activeTab, setActiveTab] = useState("cadastro")

    const getData = async () => {
        try {
            setWaiting(true)

            const data = await apiCnpjConsulta.getData(cnpjValue)

            const data_situacao_cadastral = data.data_situacao_cadastral ? dateFromString(data.data_situacao_cadastral) : null
            const data_situacao_especial = data.data_situacao_especial ? dateFromString(data.data_situacao_especial) : null
            const data_inicio_atividade = data.data_inicio_atividade ? dateFromString(data.data_inicio_atividade) : null
            const data_opcao_pelo_mei = data.data_opcao_pelo_mei ? dateFromString(data.data_opcao_pelo_mei) : null
            const data_exclusao_do_mei = data.data_exclusao_do_mei ? dateFromString(data.data_exclusao_do_mei) : null
            const data_opcao_pelo_simples = data.data_opcao_pelo_simples ? dateFromString(data.data_opcao_pelo_simples) : null
            const data_exclusao_do_simples = data.data_exclusao_do_simples ? dateFromString(data.data_exclusao_do_simples) : null

            setDados({
                ativa: data.ativa,
                situacao: data.descricao_situacao_cadastral
                    + (!data.ativa && data.descricao_motivo_situacao_cadastral
                        ? ' - ' + data.descricao_motivo_situacao_cadastral
                        : ''
                    ),
                data_situacao: data_situacao_cadastral && data_situacao_cadastral.toLocaleDateString('pt-br'),
                natureza_juridica: data.codigo_natureza_juridica + " - " + data.natureza_juridica,
                porte: data.porte,
                tipo: data.descricao_identificador_matriz_filial,
                razao_social: data.razao_social,
                nome_fantasia: data.nome_fantasia,
                endereco: data.descricao_tipo_de_logradouro + " " + data.logradouro + " ",
                numero: data.numero,
                complemento: data.complemento,
                bairro: data.bairro,
                municipio: data.municipio + (data.codigo_municipio_ibge ? ` (${data.codigo_municipio_ibge})` : ''),
                regiao_imediata: data.codigo_regiao_imediata ? `${data.nome_regiao_imediata} (${data.codigo_regiao_imediata})` : '',
                regiao_intermediaria: data.codigo_regiao_intermediaria ? `${data.nome_regiao_intermediaria} (${data.codigo_regiao_intermediaria})` : '',
                cep: formataCEP(data.cep),
                uf: data.uf,
                nome_cidade_no_exterior: data.nome_cidade_no_exterior || '',
                pais: data.pais || '',
                email: data.email,
                telefone_1: formataTelefone(data.ddd_telefone_1),
                telefone_2: formataTelefone(data.ddd_telefone_2),
                fax: formataTelefone(data.ddd_fax),
                situacao_especial: data.situacao_especial || '',
                data_situacao_especial: data_situacao_especial && data_situacao_especial.toLocaleDateString('pt-br'),
                data_inicio_atividade: data_inicio_atividade && data_inicio_atividade.toLocaleDateString('pt-br'),
                capital_social: data.capital_social.toLocaleString('pt-br', { style: "currency", currency: 'BRL' }),
                qualificacao_responsavel: data.qualificacao_responsavel,
                forma_de_tributacao: data.forma_de_tributacao || 'NÃO INFORMADO PELA RFB',
                ente_federativo_responsavel: data.ente_federativo_responsavel,
                opcao_pelo_mei: data.opcao_pelo_mei === "S" ? "SIM" : "NÃO",
                data_opcao_pelo_mei: data_opcao_pelo_mei && data_opcao_pelo_mei.toLocaleDateString('pt-br'),
                data_exclusao_do_mei: data_exclusao_do_mei && data_exclusao_do_mei.toLocaleDateString('pt-br'),
                opcao_pelo_simples: data.opcao_pelo_simples === "S" ? "SIM" : "NÃO",
                data_opcao_pelo_simples: data_opcao_pelo_simples && data_opcao_pelo_simples.toLocaleDateString('pt-br'),
                data_exclusao_do_simples: data_exclusao_do_simples && data_exclusao_do_simples.toLocaleDateString('pt-br'),
                cnae_principal: data.cnae_fiscal + ' - ' + data.cnae_fiscal_descricao,
                cnaes_secundarios: data.cnaes_secundarios,
                socios: data.qsa
            })

            setEmpresaSocia(data.empresas_que_e_socia)

            const dataMesmoGrupo = await apiCnpjConsulta.getSameGroup(cnpjValue)
            setMesmoGrupo(dataMesmoGrupo.map(c => ({
                label: formataCNPJ(c.cnpj),
                value: c.cnpj,
                ...c
            })))

            setWaiting(false)
        } catch (error) {
            showError(error)
        }
    }

    const getDataByPartner = async () => {
        try {
            const data = await apiCnpjConsulta.getByPartner({
                nomeSocio: socioOutraEmpresa.nome_socio,
                cnpjCpfSocio: socioOutraEmpresa.cnpj_cpf_do_socio,
                cnpjDesconsiderar: cnpjValue
            })

            setOutrasEmpresas(data)

        } catch (error) {
            showError(error)
        }
    }

    useEffect(() => {
        setDados({})
        setSocioOutraEmpresa(null)
        setMesmoGrupo(null)
        setEmpresaSocia(null)
        setActiveTab('cadastro')

        if (cnpjValue.length === 14) (async () => await getData())()
        // eslint-disable-next-line
    }, [cnpjValue])

    useEffect(() => {
        if (socioOutraEmpresa) {
            (async () => await getDataByPartner())()
        } else {
            setOutrasEmpresas(null)
            setEmpresaSocia(null)
        }
        // eslint-disable-next-line
    }, [socioOutraEmpresa])

    const toaster = useToaster();
    const showError = error => showMessage({ toaster, errorObject: error, executeFirst: () => setWaiting(false) })

    const cnpjValueRef = useRef()

    const handleClear = () => {
        if (waiting) return

        setCnpjValue('')
        setWaiting(false)
        cnpjValueRef.current.focus()
    }

    const handleCloseModalSocioOutraEmpresa = () => setSocioOutraEmpresa(null)
    const handleViewEmpresa = cnpjView => {
        setSocioOutraEmpresa(null)
        setOutrasEmpresas(null)
        setEmpresaSocia(null)
        setActiveTab('cadastro')
        setCnpjValue(cnpjView)
    }

    return (
        <Stack direction="column" alignItems={null} className="cnpj-consultar" >

            <div className='datatable-title'>Consulta CNPJ</div>

            <Panel bordered shaded className={`data-panel ${waiting && cnpj ? "loading-full-screen" : ''}`}>
                {!cnpj &&
                    <Stack spacing={15} justifyContent="flex-start" alignItems="flex-end">
                        <Stack spacing={3} direction="column" alignItems="flex-start">
                            <label>Informe o CNPJ</label>
                            <MaskedInput
                                ref={cnpjValueRef}
                                autoFocus
                                disabled={waiting}
                                value={cnpjValue}
                                onChange={value => {
                                    if (!waiting) {
                                        const auxValue = value.replace(/\./g, '').replace(/\//g, '').replace(/-/g, '').trim()
                                        setCnpjValue(auxValue)
                                    }
                                }}
                                mask={mask.cnpj}
                                placeholderChar={'\u2000'}
                                placeholder={'00.000.000/0000-00'}
                            />
                        </Stack>
                        <Button appearance="ghost" color="blue" onClick={handleClear} disabled={waiting}>
                            Limpar
                        </Button>
                        {
                            mesmoGrupo && mesmoGrupo.length
                                ? <InputPicker
                                    value={null}
                                    placeholder="Consultar empresas do mesmo grupo"
                                    onChange={setCnpjValue}
                                    data={mesmoGrupo}
                                    style={{ width: 300 }}
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div className={item.ativa ? "" : "cnpj-consultar-cnpj-mesmo-grupo-inativa"}>
                                                {label} - {item.matriz_filial} ({item.descricao_situacao})
                                            </div>
                                        );
                                    }}
                                />
                                : <></>
                        }
                        {
                            waiting && <Loader size="sm" content="Aguarde... consultando CNPJ" />
                        }
                    </Stack>
                }

                {cnpj && waiting
                    ? <Loading />
                    : <>
                        <FlexboxGrid justify="start" align="bottom" style={{ paddingTop: 10 }}>
                            <DadosField label={'Razão Social'} value={dados.razao_social} size={{ lg: 16 }} styleContainer={{ paddingLeft: 0 }} />
                            <DadosField label={'Nome Fantasia'} value={dados.nome_fantasia} size={{ lg: 8 }} styleContainer={{ paddingRight: 0 }} />
                        </FlexboxGrid>

                        <Tabs activeKey={activeTab} onSelect={setActiveTab} style={{ width: '100%', marginTop: 15 }}>
                            <Tabs.Tab eventKey="cadastro" title="Cadastro">
                                <DadosCadastro dados={dados} />
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="cnae" title="CNAE">
                                <DadosCNAE dados={dados} />
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="socios" title="Sócios">
                                <DadosSocios dados={dados} setSocioOutraEmpresa={setSocioOutraEmpresa} handleViewEmpresa={handleViewEmpresa} />
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="empresas_socia" title="Empresas que é sócia">
                                <DadosEmpresasSocias showMsg={dados.razao_social} dados={empresaSocia} handleViewEmpresa={handleViewEmpresa} />
                            </Tabs.Tab>
                        </Tabs>
                    </>
                }

            </Panel>

            <Modal
                open={socioOutraEmpresa}
                onClose={handleCloseModalSocioOutraEmpresa}
                className="cnpj-consultar-modal-empresa-socio"
            >
                <Modal.Header>
                    <Modal.Title>
                        {socioOutraEmpresa && socioOutraEmpresa.nome_socio}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {!outrasEmpresas
                        ? <Loader size="lg" center />
                        : <DadosEmpresaMesmoSocio dados={outrasEmpresas} handleViewEmpresa={handleViewEmpresa} />
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={handleCloseModalSocioOutraEmpresa} appearance="ghost" color="orange">
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

        </Stack >
    )
}

export default CnpjConsultar